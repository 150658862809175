var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "filters-cropped": _vm.cropped,
        "filters-widened": _vm.widenOptions
      }
    },
    [
      _vm.showSearchBar
        ? _c(
            "b-field",
            [
              _c("b-input", {
                attrs: {
                  placeholder: "Search",
                  icon: "magnify",
                  "icon-right-clickable": "",
                  "icon-right": "close"
                },
                on: {
                  "icon-right-click": function($event) {
                    _vm.optionSearch = ""
                  }
                },
                model: {
                  value: _vm.optionSearch,
                  callback: function($$v) {
                    _vm.optionSearch = $$v
                  },
                  expression: "optionSearch"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.filteredOptions, function(option) {
        return _c("CheckboxSourceRow", {
          key: option.key + "-" + option.active,
          class: {
            disabled:
              (_vm.disableLabelOptions === "none" &&
                (option.title || option.key) === "No label") ||
              (_vm.disableLabelOptions === "not_none" &&
                (option.title || option.key) !== "No label")
          },
          attrs: {
            amount: option.count,
            name: option.title || option.key,
            value: option.active,
            wide: _vm.wide
          },
          on: {
            input: function($event) {
              return _vm.$emit("optionClicked", {
                option: option,
                type: _vm.type
              })
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }