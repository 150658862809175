<template>
  <div :class="{ 'filters-cropped': cropped, 'filters-widened': widenOptions }">
    <b-field v-if="showSearchBar">
      <b-input
        v-model="optionSearch"
        placeholder="Search"
        icon="magnify"
        icon-right-clickable
        icon-right="close"
        @icon-right-click="optionSearch = ''"
      >
      </b-input>
    </b-field>
    <CheckboxSourceRow
      v-for="option in filteredOptions"
      :key="`${option.key}-${option.active}`"
      :amount="option.count"
      :name="option.title || option.key"
      :value="option.active"
      :wide="wide"
      :class="{
        disabled:
          (disableLabelOptions === 'none' &&
            (option.title || option.key) === 'No label') ||
          (disableLabelOptions === 'not_none' &&
            (option.title || option.key) !== 'No label')
      }"
      @input="$emit('optionClicked', { option, type: type })"
    />
  </div>
</template>

<script>
import CheckboxSourceRow from '@c/features/search-results/molecules/CheckboxSourceRow.vue'
import { SearchFacet } from 'umanai-vuex/src/store/modules/resources'

export default {
  name: 'HorizontalFilterBlock',
  components: {
    CheckboxSourceRow
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    cropAmount: 10,
    optionSearch: ''
  }),
  computed: {
    cropped() {
      return this.options.length > this.cropAmount
    },
    showSearchBar() {
      return (
        [SearchFacet.authors].includes(this.type) ||
        (this.type === SearchFacet.file_locations && this.options.length >= 10)
      )
    },
    widenOptions() {
      return [SearchFacet.authors].includes(this.type)
    },
    filteredOptions() {
      return this.options
        .filter(
          (option) =>
            option.title
              ?.toLowerCase()
              .includes(this.optionSearch.toLowerCase()) ||
            option.key?.toLowerCase().includes(this.optionSearch.toLowerCase())
        )
        .sort((x, y) =>
          ['modified_dates', 'created_dates'].includes(this.type)
            ? Number(y.title || y.key) - Number(x.title || x.key)
            : y.count - x.count
        )
    },
    wide() {
      return this.type === 'file_locations'
    },
    disableLabelOptions() {
      if (
        this.type !== 'labels' ||
        this.options.filter((option) => option.active).length === 0
      )
        return ''
      if (
        this.options.find(
          (option) => (option.title || option.key) === 'No label'
        )?.active
      )
        return 'not_none'
      return 'none'
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  &-cropped {
    $searchbar-height: 4.25rem;
    $filter-entry-height: 1.2rem;
    max-height: calc($searchbar-height + 10 * $filter-entry-height);
    overflow-y: auto;
  }

  &-widened {
    width: 25ch;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
