var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.handleClickaway,
          expression: "handleClickaway"
        }
      ],
      staticClass: "horizontal-filters",
      attrs: { id: "horizontal-filters" }
    },
    _vm._l(_vm.filters, function(filter) {
      return _c(
        "div",
        { key: filter.type, staticClass: "horizontal-filters-dropdown" },
        [
          _c(
            "div",
            {
              staticClass: "horizontal-filters-dropdown-trigger",
              class: {
                "horizontal-filter-dropdown-trigger": true,
                active: filter.options.some(function(opt) {
                  return opt.active
                })
              },
              attrs: {
                id:
                  "intercom-" +
                  filter.title
                    .toLowerCase()
                    .split(" ")
                    .join("-")
              },
              on: {
                click: function($event) {
                  _vm.toggledDropdown =
                    _vm.toggledDropdown === filter.type ? "" : filter.type
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "horizontal-filters-dropdown-trigger-title" },
                [
                  _vm._v(_vm._s(filter.title) + " "),
                  filter.options.some(function(opt) {
                    return opt.active
                  })
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "horizontal-filters-dropdown-trigger-title-count"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              filter.options.filter(function(opt) {
                                return opt.active
                              }).length
                            )
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _c("img", {
                staticClass: "horizontal-filters-dropdown-trigger-chevron",
                attrs: {
                  src: require("@/assets/icons/dropdown-chevron.svg"),
                  alt: ""
                }
              })
            ]
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.toggledDropdown === filter.type
              ? _c(
                  "div",
                  { staticClass: "horizontal-dropdown-content-wrapper" },
                  [
                    _c("div", { staticClass: "horizontal-dropdown-content" }, [
                      _c(
                        "div",
                        { staticClass: "horizontal-dropdown-content-block" },
                        [
                          _c(
                            "HorizontalFilterBlock",
                            _vm._g(
                              {
                                key:
                                  filter.type +
                                  "-" +
                                  _vm.selectedFilterType +
                                  "-" +
                                  _vm.selectedFilterOption +
                                  "-" +
                                  _vm.selectedFilterActive,
                                attrs: {
                                  options: filter.options,
                                  type: filter.type
                                }
                              },
                              _vm.$listeners
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "horizontal-dropdown-content-buttons" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "horizontal-dropdown-content-buttons-clear",
                              on: {
                                click: function($event) {
                                  return _vm.handleClear(filter.type)
                                }
                              }
                            },
                            [_vm._v(" Clear all ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "horizontal-dropdown-content-buttons-save",
                              on: {
                                click: function($event) {
                                  return _vm.handleSave(filter.type)
                                }
                              }
                            },
                            [_vm._v(" Save ")]
                          )
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }