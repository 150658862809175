<template>
  <div
    id="horizontal-filters"
    v-on-clickaway="handleClickaway"
    class="horizontal-filters"
  >
    <div
      v-for="filter in filters"
      :key="filter.type"
      class="horizontal-filters-dropdown"
    >
      <div
        :id="`intercom-${filter.title.toLowerCase().split(' ').join('-')}`"
        :class="{
          'horizontal-filter-dropdown-trigger': true,
          active: filter.options.some((opt) => opt.active)
        }"
        class="horizontal-filters-dropdown-trigger"
        @click="
          toggledDropdown = toggledDropdown === filter.type ? '' : filter.type
        "
      >
        <span class="horizontal-filters-dropdown-trigger-title"
          >{{ filter.title }}
          <span
            v-if="filter.options.some((opt) => opt.active)"
            class="horizontal-filters-dropdown-trigger-title-count"
            >{{ filter.options.filter((opt) => opt.active).length }}</span
          ></span
        >
        <img
          src="@/assets/icons/dropdown-chevron.svg"
          alt=""
          class="horizontal-filters-dropdown-trigger-chevron"
        />
      </div>
      <transition name="fade">
        <div
          v-if="toggledDropdown === filter.type"
          class="horizontal-dropdown-content-wrapper"
        >
          <div class="horizontal-dropdown-content">
            <div class="horizontal-dropdown-content-block">
              <HorizontalFilterBlock
                :key="`${filter.type}-${selectedFilterType}-${selectedFilterOption}-${selectedFilterActive}`"
                :options="filter.options"
                :type="filter.type"
                v-on="$listeners"
              />
            </div>
            <div class="horizontal-dropdown-content-buttons">
              <p
                class="horizontal-dropdown-content-buttons-clear"
                @click="handleClear(filter.type)"
              >
                Clear all
              </p>
              <p
                class="horizontal-dropdown-content-buttons-save"
                @click="handleSave(filter.type)"
              >
                Save
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapActions, mapGetters } from 'vuex'
import HorizontalFilterBlock from './HorizontalFilterBlock.vue'

export default {
  name: 'HorizontalFilters',
  components: {
    HorizontalFilterBlock
  },
  mixins: [clickaway],
  props: {
    topFilters: {
      type: Array,
      default: () => []
    },
    filtersLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    cropAmount: 10,
    toggledDropdown: '',
    optionsKey: 0
  }),
  computed: {
    ...mapGetters(['resourceTypeFilter', 'dateFilters', 'selectedFilter']),
    activeView() {
      return this.$route.query.view || 'allContent'
    },
    filters() {
      if (this.resourceTypeFilter === 'slides')
        return this.topFilters.filter((f) => f.type !== 'mimetypes')
      return this.topFilters
    },
    dateLimits() {
      const max = new Date()
      let min = null
      if (this.activeView === 'recent')
        min = new Date(max.getFullYear(), max.getMonth() - 1, max.getDate())

      return { min, max }
    },
    selectedFilterType() {
      return this.selectedFilter?.type || ''
    },
    selectedFilterOption() {
      return (
        this.selectedFilter?.option?.title ||
        this.selectedFilter?.option?.key ||
        this.selectedFilter?.option?.name ||
        ''
      )
    },
    selectedFilterActive() {
      return this.selectedFilter?.active || false
    }
  },
  created() {
    let createdValue = this.$route.query.created_dates
    if (createdValue)
      createdValue = createdValue.split(',').map((d) => new Date(d))
    let modifiedValue = this.$route.query.modified_dates
    if (modifiedValue)
      modifiedValue = modifiedValue.split(',').map((d) => new Date(d))
    this.setDateFilter({
      value: createdValue,
      filterType: 'created_dates'
    }),
      this.setDateFilter({
        value: modifiedValue,
        filterType: 'modified_dates'
      })
  },
  methods: {
    ...mapActions(['setDateFilter']),
    formatDate(date) {
      return this.$moment(date).format('D MMM YYYY')
    },
    handleClear(type) {
      this.$emit('clearFiltersByType', type)
      this.toggledDropdown = ''
    },
    handleSave(type) {
      this.$emit('saveFilters', type)
      this.toggledDropdown = ''
    },
    handleClickaway() {
      this.toggledDropdown = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal-filters {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &-dropdown {
    position: relative;

    &-trigger {
      color: #60666b;
      border: 1px solid #dddfe2;
      border-radius: 4px;
      padding: 0.3rem 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      &.active {
        border: 1px solid $primary;
        color: $primary;
      }

      &-title {
        white-space: nowrap;

        &-count {
          color: $primary;
          background-color: #ebf1ff;
          min-width: 1ch;
          border-radius: 999rem;
          padding: 0 0.5rem;
          margin-left: 0.5rem;
        }
      }

      &-chevron {
        width: 0.75rem;
      }
    }
  }
}

.horizontal-dropdown-content {
  background: white;
  box-shadow: 0 0.5em 1em -0.125em rgb(31 31 31 / 10%),
    0 0px 0 1px rgb(31 31 31 / 2%);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 30ch;

  &-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    padding-top: 0.5rem;
  }

  &-block {
    padding: 1rem;
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-top: 1px solid #dddfe2;

    &-clear {
      color: $primary;
      cursor: pointer;
    }

    &-save {
      color: white;
      background-color: $primary;
      padding: 0.3rem 1rem;
      border-radius: 999rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
