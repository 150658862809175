var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-between is-vcentered grid-container" },
    [
      _c("div", { staticClass: "grid-element" }, [
        _c("div", { staticClass: "u-columns" }, [
          _c(
            "div",
            { staticClass: "u-column is-narrow" },
            [
              _c("b-checkbox", {
                attrs: {
                  value: _vm.value,
                  type: "is-primary",
                  indeterminate: _vm.indeterminate,
                  size: "is-small"
                },
                nativeOn: {
                  click: function($event) {
                    $event.preventDefault()
                    return function() {
                      if (!_vm.preventValueChange) {
                        _vm.$emit("input", !_vm.value)
                      }
                    }.apply(null, arguments)
                  }
                }
              })
            ],
            1
          ),
          _vm.icon
            ? _c(
                "div",
                {
                  staticClass: "u-column is-narrow mr-3 cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", !_vm.value)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "fit" }, [
                    _c("div", { staticClass: "icon-backdrop" }, [
                      _c("img", {
                        staticStyle: {
                          width: "12px",
                          height: "12px",
                          display: "block"
                        },
                        attrs: { src: _vm.icon }
                      })
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "u-column cursor-pointer name-container",
              class: { wide: _vm.wide },
              on: {
                click: function($event) {
                  return _vm.$emit("input", !_vm.value)
                }
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "body",
                  staticStyle: {
                    "max-width": "100%",
                    "overflow-x": "hidden",
                    "text-overflow": "ellipsis",
                    "white-space": "nowrap"
                  },
                  attrs: { title: _vm.name }
                },
                [_vm._v(" " + _vm._s(_vm.name) + " ")]
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "is-narrow" }, [
        _c("p", { staticClass: "body label xxs blue bold label-background" }, [
          _vm._v(" " + _vm._s(_vm.amount) + " ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }