





























































import Vue from 'vue'

export default Vue.extend({
  name: 'CheckboxSourceRow',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    amount: {
      type: Number,
      default: undefined
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    preventValueChange: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    }
  }
})
